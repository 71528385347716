import React from 'react'
import Helmet from 'react-helmet'
import BookletsComponent from '../components/booklets'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const Booklets = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const pageTitle = "Booklets";
  const booklets = data.allContentfulBooklet.edges.map(({ node }) => node);

  return (
    <Layout>
      <div className="post">
        <Helmet title={`${pageTitle} | ${siteTitle}`} />
        <h2 className="title">{pageTitle}</h2>
        <BookletsComponent booklets={booklets} />
      </div>
    </Layout>
  );
}

export default Booklets

export const bookletsQuery = graphql`
  query BookletsQuery {
    site {
      ...siteMeta
    }
    allContentfulBooklet {
      edges {
        node {
          ...bookletSummary
        }
      }
    }
  }`;
